var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.desafio ? _c('div', [_c('b-row', [_c('b-col', [_c('b-card', {
    staticClass: "header-marcar_consulta bg-gradient-primary"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', [_c('h1', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "ShieldIcon",
      "size": "30"
    }
  }), _vm._v(" Avaliar Arboturma ")], 1)])], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('h2', {
    staticClass: "mt-1"
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "SettingsIcon",
      "size": "30"
    }
  }), _vm._v(" Aluno(s): " + _vm._s(_vm.obterNomeDosAlunos()) + " ")], 1)])], 1), _c('b-row', {
    staticClass: "mt-1"
  }, [_c('b-col', [_c('b-card', [_c('b-row', [_c('b-col', [_c('div', {
    staticClass: "mb-5"
  }, [_c('h3', {
    staticClass: "mt-3"
  }, [_vm._v("Respostas do Desafio")]), _c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Link do Vídeo")]), _vm.desafio.videoUrl ? _c('a', {
    attrs: {
      "href": _vm.desafio.videoUrl || '#',
      "target": "_blank",
      "rel": "noreferrer"
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "YoutubeIcon",
      "size": "30"
    }
  }), _vm._v(" Vídeo ( " + _vm._s(_vm.desafio.videoUrl || 'Sem link') + ") ")], 1) : _vm._e(), !_vm.desafio.videoUrl ? _c('p', [_c('feather-icon', {
    staticClass: "mr-50",
    attrs: {
      "icon": "YoutubeIcon",
      "size": "30"
    }
  }), _vm._v(" Vídeo ( Sem Link) ")], 1) : _vm._e()]), _c('h3', {
    staticClass: "mt-3"
  }, [_vm._v("Avaliação")]), _c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Nota")]), _c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "pontuacao"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "id": "pontuacao",
      "placeholder": "Inserir pontuação"
    },
    model: {
      value: _vm.form.score,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "score", $$v);
      },
      expression: "form.score"
    }
  })], 1), _c('h5', {
    staticClass: "mt-2"
  }, [_vm._v("Comentário de Feedback")]), _c('b-form-textarea', {
    attrs: {
      "id": "textarea-default",
      "placeholder": "Inserir descrição",
      "rows": "3"
    },
    model: {
      value: _vm.form.feedback,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "feedback", $$v);
      },
      expression: "form.feedback"
    }
  }), _c('b-form-group', [_c('b-form-checkbox', {
    staticClass: "mt-2",
    attrs: {
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    model: {
      value: _vm.form.approved,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "approved", $$v);
      },
      expression: "form.approved"
    }
  }, [_c('p', {
    staticClass: "fs-3"
  }, [_vm._v("Aprovado")])])], 1)], 1)], 1), _c('b-row', {
    staticClass: "mt-4 pb-5 justify-content-between w-100 m-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "button-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.back();
      }
    }
  }, [_vm._v(" Cancelar ")])], 1), _c('b-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "button-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm._v(" Salvar Avaliação ")])], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }