<template>
  <div v-if="desafio">
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="ShieldIcon" size="30" class="mr-50" />
                Avaliar Arboturma
              </h1>
              <!-- <h4 class="text-white">Inicie uma Aplicação de Jogo:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <h2 class="mt-1">
          <feather-icon icon="SettingsIcon" size="30" class="mr-50" />
          Aluno(s): {{ obterNomeDosAlunos() }}
        </h2>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col>
        <b-card>
          <b-row>
            <b-col>
              <div class="mb-5">
                <h3 class="mt-3">Respostas do Desafio</h3>
                <h5 class="mt-2">Link do Vídeo</h5>
                <a
                  v-if="desafio.videoUrl"
                  v-bind:href="desafio.videoUrl || '#'"
                  target="_blank"
                  rel="noreferrer"
                >
                  <feather-icon icon="YoutubeIcon" size="30" class="mr-50" />
                  Vídeo ( {{ desafio.videoUrl || 'Sem link' }})
                </a>
                <p v-if="!desafio.videoUrl">
                  <feather-icon icon="YoutubeIcon" size="30" class="mr-50" />
                  Vídeo ( Sem Link)
                </p>
              </div>
              <h3 class="mt-3">Avaliação</h3>
              <h5 class="mt-2">Nota</h5>
              <b-form-group label="" label-for="pontuacao">
                <b-form-input
                  type="number"
                  id="pontuacao"
                  placeholder="Inserir pontuação"
                  v-model="form.score"
                />
              </b-form-group>

              <h5 class="mt-2">Comentário de Feedback</h5>
              <b-form-textarea
                id="textarea-default"
                placeholder="Inserir descrição"
                rows="3"
                v-model="form.feedback"
              />

              <b-form-group>

                <b-form-checkbox
                  class="mt-2"
                  v-model="form.approved"
                  name="check-button"
                  switch
                  inline
                >
                  <p class="fs-3">Aprovado</p>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-4 pb-5 justify-content-between w-100 m-0">
            <b-col cols="auto">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="button-right"
                @click="back()"
              >
                Cancelar
              </b-button>
            </b-col>
            <b-col cols="auto">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="button-right"
                @click="submit()"
              >
                Salvar Avaliação
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import challengeService from '@/services/challengeService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import {
  BButton,
  BCard,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
  BRow,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: ['id'],
  data: () => ({
    desafio: null,
    modelo: {},
    form: {
      id: null,
      approved: false,
      feedback: null,
      score: null,
    },
  }),
  methods: {
    getPreparedEntity() {
      return {
        ...this.form,
        id: this.id,
      }
    },
    async submit() {
      try {
        showSpinner()
        const entity = JSON.parse(JSON.stringify(this.getPreparedEntity()))
        await challengeService.avaliar('arboturma', entity)

        hideSpinner()
        this.$bvToast.toast('Desafio avaliado com sucesso!', {
          title: 'Sucesso',
          variant: 'success',
          solid: true,
          autoHideDelay: 5000,
        })
        // this.$router.push({ name: 'todosdesafios' })
      } catch (error) {
        hideSpinner()
        this.$bvToast.toast('Erro ao avaliar o Desafio!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    async getDesafioInfo() {
      try {
        this.desafio = await challengeService.getArboTurma(this.id)
        this.form.approved = this.desafio.approved
        this.form.feedback = this.desafio.feedback
        this.form.score = this.desafio.score
      } catch (error) {
        this.$bvToast.toast('Erro ao carregar Desafio!', {
          title: 'Erro',
          variant: 'danger',
          solid: true,
          autoHideDelay: 5000,
        })
      }
    },
    obterNomeDosAlunos() {
      if (!this.desafio) return ''
      return this.desafio.members.map(aluno => aluno.user.fullName).join(', ')
    },
    back() {
      this.$router.push({ name: 'avaliar' })
    },
  },
  mounted() {
    this.getDesafioInfo()
  },
}
</script>

<style>
.list-group-item {
  transition: all 1s;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
